.img5 {
  margin-top: 30px;
  object-fit: cover;
}

.img5 img {
  border: 1px solid black;
  width: 100%;
  height: 30vw;

  border-radius: 10px;
}
.Single h2 {
  font-size: 30px;
  text-transform: capitalize;
  color: rgb(53, 53, 53);
}
.Single p {
  color: rgb(53, 53, 53);
  font-size: 16px;
  line-height: 2;
  margin-bottom: 2vw;
  font-family: Poppins;
  text-align: justify;
}
.Single span {
  font-weight: bold;
}
@media screen and (max-width: 1024px){
  .privacy h1 {
      font-size: 30px;
     
    }
    .Single h2 {
      font-size: 20px;
      
    }
    .Single p {
     
      font-size: 14px;
     
    }
    .Single ul li {
      
      font-size: 14px;
     
       
    }
}
@media screen and (max-width: 768px){
  .privacy h1 {
      font-size: 25px;
     
    }
    .img5 img{
      height:40vw ;
      object-fit: cover;
      
    
    }
    .Single h2 {
      font-size: 15px;
      
    }
    .Single p {
     
      font-size: 11px;
     
    }
    .Single ul li {
      
      font-size: 11px;
     
       
    }
}