.containerHeadt {
  margin-top: 3.1%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containert {
  width: 81vw;
}


.containert h1 {
  color: black;
  font-size: 2vw;
  font-family: "Poppin";
}

.seeMore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px;
}

.more button {
  align-items: center;
  border-radius: 5px;
  background: #c43942;
  color: #fff;
  height: 35px;
  width: fit-content;
  display: flex;
  padding: 0.5vw 0.5vw;
  font-size: 14px;

  cursor: pointer;
}

.more button:hover {
  background: #a32f34;
  transition: background-color 0.3s ease;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .containert {
    width: 90%;
  }

  .containert h1 {
    font-size: 1.8vw;
  }

  .more button {
    font-size: 18px;
    padding: 0.6vw 1vw;
  }
}

@media screen and (max-width: 768px) {
  .containerHeadt {
    margin-top: 5%;
  }

  .containert {
    width: 95%;
  }

  .containert h1 {
    font-size: 1.5vw;
  }

  .more button {
    font-size: 16px;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 576px) {
  .containerHeadt {
    margin-top: 4%;
  }

  .containert {
    width: 100%;
    padding: 0 15px;
  }

  .containert h1 {
    font-size: 4vw;
    text-align: center;
  }

  .seeMore {
    align-items: center;
    margin-top: 20px;
    margin-bottom: -30px;
  }

  .more button {
    font-size: 14px;
    padding: 8px 12px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 400px) {
  .containert h1 {
    font-size: 5vw;
  }

  .more button {
    font-size: 12px;
    padding: 1vw;
  }
}
