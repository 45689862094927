.container{
    height: 100vh;
    background-color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.containerr{
    height: 100vh;
   background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.images{
    margin-top: 2vw;
}
.images img{
    padding: 2px;

 
    width: 150px;
    height: 50px;
    object-fit: contain;
}
    
 
