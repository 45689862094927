.fees-and-charges-container {
  padding: 20px;
  margin: 0 10%;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fees-and-charges-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  color: #343434;
  justify-content: center;
}


.description {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.description h2{
  font-weight: 400;
}
.description strong{
  font-weight: bold;
}

.description table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 18px;
}

.description th,
.description td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.description th {
  background-color: #f8f8f8;
  font-weight: bold;
}

.description tr:nth-child(even) {
  background-color: #f9f9f9;
}

.description p {
  margin: 10px 0;
}

.description h2,
.description h3 {
  margin: 15px 0 10px;
  color: #444;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .fees-and-charges-container {
    margin: 0 5%; /* Reduce the margin on tablets */
  }

  .fees-and-charges-container h1 {
    font-size: 20px; /* Smaller heading size for tablets */
  }

  .description {
    font-size: 14px; /* Reduce font size for readability on tablets */
  }

  .description table {
    margin-top: 15px; /* Adjust margin */
  }

  .description th,
  .description td {
    padding: 8px; /* Reduced padding for smaller devices */
  }

  .description h2,
  .description h3 {
    font-size: 18px; /* Smaller subheading size */
  }
}

@media screen and (max-width: 768px) {
  .fees-and-charges-container {
    margin: 0 3%; /* Further reduce margin on mobile */
    padding: 15px; /* Slightly less padding */
  }

  .fees-and-charges-container h1 {
    font-size: 18px; /* Further reduce heading size */
  }

  .description {
    font-size: 14px; /* Reduce font size for smaller screens */
  }

  .description table {
    margin-top: 15px;
  }

  .description th,
  .description td {
    padding: 6px; /* Less padding */
    font-size: 12px; /* Smaller text size for table cells */
  }

  .description p {
    font-size: 12px; /* Adjust paragraph font size */
  }

  .description h2,
  .description h3 {
    font-size: 16px; /* Adjust subheading size */
  }
}

@media screen and (max-width: 480px) {
  .fees-and-charges-container {
    margin: 0 2%; /* Maximize space for very small screens */
  }

  .fees-and-charges-container h1 {
    font-size: 16px; /* Significantly reduce heading size */
  }

  .description {
    font-size: 12px; /* Make text smaller for readability */
  }

  .description table {
    margin-top: 10px; /* Adjust margin */
  }

  .description th,
  .description td {
    padding: 5px; /* Minimal padding */
    font-size: 10px; /* Reduce text size for table cells */
  }

  .description h2,
  .description h3 {
    font-size: 14px; /* Adjust subheading size */
  }
}
