.tainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 16px;
}

.Headingg {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  text-align: center;
}

.ButtonandTitle {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.backButton {
  display: flex;
  align-items: center;
  color: #c43942;
}

.backButton:hover {
  cursor: pointer;
}

.back {
  color: white;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.paginationButton {
  margin: 5px;
  padding: 5px 15px;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.paginationButton:hover {
  background-color: darkgray;
}

.activePage {
  background-color: blue;
  font-weight: bold;
}

.heading5 {
  margin: 30px 10px;
}

.Headig {
  margin: 5px;
  padding: 0 5px;
}

.trendingPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 16px;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .tainer {
    padding: 0 10px;
  }

  .Headingg {
    width: 90%;
    text-align: center;
  }

  .paginationContainer {
    margin-top: 15px;
  }

  .paginationButton {
    font-size: 14px;
    padding: 4px 12px;
  }

  .heading5 {
    margin: 25px 10px;
  }

  .trendingPageContainer {
    padding: 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .tainer {
    padding: 0 8px;
  }

  .Headingg {
    width: 90%;
    text-align: center;
  }

  .paginationContainer {
    margin-top: 15px;
  }

  .paginationButton {
    font-size: 12px;
    padding: 3px 10px;
  }

  .heading5 {
    margin: 20px 5px;
  }

  .trendingPageContainer {
    padding: 0 8px;
  }
}

@media screen and (max-width: 576px) {
  .tainer {
    flex-direction: column;
    padding: 0 8px;
    margin-top: 20px;
  }

  .Headingg {
    width: 100%;
    text-align: center;
  }

  .paginationContainer {
    margin-top: 20px;
  }

  .paginationButton {
    font-size: 14px;
    padding: 3px 8px;
  }

  .heading5 {
    margin: 15px 5px;
  }

  .trendingPageContainer {
    padding: 0 8px;
  }
}

@media screen and (max-width: 400px) {
  .tainer {
    flex-direction: column;
    padding: 0 8px;
    margin-top: 15px;
  }

  .Headingg {
    width: 100%;
    text-align: center;
  }

  .paginationContainer {
    margin-top: 15px;
  }

  .paginationButton {
    font-size: 12px;
    padding: 3px 8px;
  }

  .heading5 {
    margin: 10px 5px;
  }

  .trendingPageContainer {
    padding: 0 5px;
  }
}
