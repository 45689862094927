.app {
  /* overflow: hidden; */
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(11, 10, 10, 0.566);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Higher than the banner */
}
.modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  width: 400px;
  height: auto;
  position: relative;
  text-align: center;
  z-index: 1001; 
  /* Ensure it's above the modal backdrop */
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.closeButton {
  position: absolute;
  top: 4px;
  right: 20px;
  background: none;
  border: none;
  font-size: 28px;
  font-weight: thin;
  cursor: pointer;

}

.closeButton:hover {
  color: red;
}

ol {
  padding-left: 20px;
}

ol li {
  margin-bottom: 10px;
}
.design {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 55px;
  overflow: hidden;
}
.design h4 {
  font-size: 20px;
  color: #c43942;
}
.btnDesign {
  display: flex;
  justify-content: center;
  /* height: 50px; */
  margin-bottom: 10px;
  color: #c43942;
}
.modalBtn {
  /* text-align: center; */
  border: 1px solid #c43942;
  width: 100px;
  height: 40px;
  
  border-radius: 5px;
}
.modalBtn:hover {
  background-color: #c43942;
  color: white;
}