.division{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  margin-top: -4vw;

}
.division2{
  /* margin-top: -10vw; */
  margin-bottom: 2vw;
}

.leftsides{
    width: 55vw;
    overflow-y: auto;
}
.flight{
    display: flex;
    gap: 2vw;
    box-shadow: 0 10px 4px rgb(0 0 0 / 8%);
  transition: 0.5s;
    border-radius: 5px;
}
.flight:hover {
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  cursor: pointer;
  transform: translateY(-5px);
}
.top{
  margin-top: 20px;
  margin-bottom: 40px;
}
.img2{
width:25vw;

}
.img2 img{


 
  width: 100%;
  height: 100%;

 
}
.description{
  margin-right: 2vw;
  padding: 2px;
  width: 30vw;
}
.description  h3{
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: bold;
   
    color: #343a3f;
    
  
  
}

.description p{
  height: 13vw;
  color: #090808;
  font-size: 16px;
  margin: 8px 0;
  text-align: left;
  overflow: hidden;
line-height: 2;
display: flex ;
justify-content: center;
align-items: center;
}
.blog .date {
  display: flex;
  align-items: center;
  
}
.date1{
  font-size: 15px;
  font-family: Poppins;
  margin-top: 0.3vw;
  margin-right: 5px;
  display: flex;
  gap: 10px;
  
}
.icon{
  font-size: 15px;
 
}
.icon1{
  font-size: 15px;
  margin-right: px;
}
.rightsides{
    width: 24.5vw;
    position: sticky;
    top : 0;
  
    
}
.subscribe {
  transition: 0.5s;
    box-shadow: 10px 10px 20px 0px rgba(194, 191, 191, 0.75);
    background-color: #c43942;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .subscribe h1 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    color: white;
    text-align: center;
  }
  .subscribe button{
    border-radius: 10px;
    height: 40px;
    cursor: pointer;
    padding: 5px 10px;
  }
  .par{

    margin-top: 30px;
    color: black;
    font-size: 15px;
    font-family: Poppins;


  }
  .pa{
    color: #ff0000;
  }
  .subscribe input {
    width: 100%;
    border-radius: 10px;
    height: 40px;
    border-color: rgb(241, 241, 236);
   
  }
  .subscribe  input::placeholder {

  
    padding-left: 10px;
    color: black;
    font-weight: 400;
    font-size: 18px;
  }
  .subscribe  input::type {

  
    padding-left: 10px;
  }
  .subscribe button {
    width: 50%;
    
    margin-top: 2vw;
    background-color: #ff0000;
    color: #fff;
    font-weight: 600;
    border: none;
    cursor: pointer;
  }
  .subscribe button:hover{
    opacity: 0.95;
  }


.store1 {
  margin-top: 1.5vw;
  text-align: center;
  color: white;
  font-size: 2vw;
  font-family: Poppin;
}
.botton1 {
  align-items: center;
  justify-content: space-between;
}
.android1 {
  display: flex;
  background-color: rgba(252, 175, 31, 0.687);
  margin-left: 6vw;
  margin-right: 6vw;
  padding: 4px 1px;
 
  align-items: center;
  justify-content: center;
  margin-top: 2vw;
  cursor: pointer;
  
}
.android1:hover{
  background-color: rgba(231, 165, 42, 0.687);
}
.android1  div {
  width: 1.3vw;
  height: 1.3vw;
}
.android1 div img {
  width: 100%;
  height: 100%;
}
.android1 h3 {
  font-family: Poppin;
  color: white;
  font-size: 1vw;
  margin-left: 0.8vw;
}

  @media screen and (max-width: 1100px){
    .img2 img{
     height: 100%;
   
    }
    .description h6{
  font-size: 22px;
}
.description p{
font-size: 13px;

}
.button1{
  padding: 2px 5px;
}
.android1{
  margin-left: 4vw;
margin-right: 4vw;
}
.android1 h3{
  font-size: 12px;




  }
}
  @media screen and (max-width: 998px){
    .division{
      grid-template-columns: repeat(1, 1fr);
    }
    .leftsides{
      width: 100%;
    }
    .rightsides{
      width: 100%;
    }
    .description{
      width: 100%;
    }
    .img2{
      width: 100%;
    }
    /* .img2 img{
      width: 100%;
     height: 100%;
    } */
    .description p{
      font-size: 15px;
      height: 20vw;
    }
    .submit{
    margin: 0 10px;
    }
  
    .android1 {
    padding: 0.8vw;
    margin-top: 3vw;
    border-radius: 7px;
    
    }
    .android1 div{
      height: 20px;
      width: 20px;
    }
    .android1 h3{
      font-size: 20px;
    }
  }
  @media screen and (max-width:697px){
.flight{
  display: flex;
  flex-direction:column;
}
.img2{
 display: flex;
 align-items: center;
 justify-content: center;
  
}
.img2 img{
  width: 95%;
  height: 40%;
}
.subscribe{
     

  width: 100%;
  
}
.button1{
  margin-left: 2vw;
  margin-right: 2vw;
}
.android1 {
padding: 1.5vw;
margin-top: 3vw;
border-radius: 7px;
margin-left: 10vw;
  margin-right: 10vw;
  padding: 4px 1px;
 

}
.android1 img{
  height: 200px;
  width: 200px;
}
.android1 h3{
  font-size: 20px;
}
  }