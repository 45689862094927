@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;700;800');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  font-size: 16px;
  text-transform: initial;
  overflow-y: auto; /* Enable vertical scrolling */
}

.contHead {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1280px;
  padding: 0 16px;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  color: #343a3f;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

p {
  margin: 0;
  color: #fff;
  line-height: 1.8;
  text-transform: initial;
}

img {
  border: none;
  max-width: 100%;
  height: auto;
}

ul {
  padding: 0;
  margin: 0 auto;
  list-style: none;
  text-decoration: none;
}

ul li {
  list-style: none;
  text-decoration: none;
}