@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
 font-family: 'Poppins', sans-serif;
}

.noticeContainer {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.noticeList h1 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 22px;
}

.noticeItem {
  background: #f8f8f8;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.noticeItem:hover {
  background: #ddd;
}

.noticeFull img {
  width: 100%;
  height: auto;
  margin-top: 15px;
  border-radius: 8px;
}

.backButton {
  background: #c43942;
  color: white;
  font-size: 14px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
}
