.containerHead6 {
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
}
.container6 {
  padding: 0;
  margin-top: 2vw;

  width: 79vw;
}
.terms {
  width: 100%;

  display: flex;
  justify-content: center;

  flex-direction: column;
}

/* .termns-description h1{
  color: #343a34;
  font-size: 24px;
} */
.termsSection h2 {
  font-size: 35px;
  font-family: Poppins;
color: #343a34;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.termsDescription h1{
 color: red;
}
.container6 h2 {
  font-size: 28px;
  font-family: Poppins;
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.container6 p {
  color: rgb(53, 53, 53);
  line-height: 2;
  font-size: 16px;
  font-family: Poppins;
  text-align: justify;
}
.container6 ul li {
    color: rgb(53, 53, 53);
  font-size: 16px;
  line-height: 2;
  text-align: justify;
  font-family: Poppins;
    margin-left: 2vw;
    list-style: disc;
   
}
@media screen and (max-width: 1024px){
    .terms h1 {
        font-size: 30px;
       
      }
      .container6 h2 {
        font-size: 20px;
        
      }
      .container6 p {
       
        font-size: 15px;
       
      }
      .container6 ul li {
        
        font-size: 15px;
       
         
      }
}
@media screen and (max-width: 768px){
    .privacy h1 {
        font-size: 25px;
       
      }
      .container6 h2 {
        font-size: 15px;
        
      }
      .container6 p {
       
        font-size: 12px;
       
      }
      .container6 ul li {
        
        font-size: 12px;
       
         
      }
}