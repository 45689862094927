

.app {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 104vh;
  background: rgba(11, 10, 10, 0.566);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Higher than the banner */
}
.modalContent {
  background: white;
  border-radius: 8px;
  max-width: 570px;
  position: relative;
  text-align: left;
  z-index: 1001; /* Ensure it's above the modal backdrop */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.closeButton:hover {
  color: red;
}

ol {
  padding-left: 20px;
}

ol li {
  margin-bottom: 10px;
}

.noticeImage {
  max-width: 100%;
  height: auto;
  display: block;
}
.design {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 550px;
  overflow: hidden;
}
.design img{
  height: 700px;
  width: auto;
}
@media (max-width:320px) {
  /* Your CSS styles go here */
  .design {
    height: 650px;
    width: auto;
  }
  .design img{
    height: 600px;
    width: auto;
  }
}
@media (min-width:321px) and (max-width:376px) {
  .design {
    height: 650px;
    width: auto;
  }
  .design img{
    height: 600px;
    width: auto;
  }
}


@media (min-width:376px) and (max-width:425px) {
  .design {
    height: 600px;
    width: 350px;
  }
  .design img{
    height: 550px;
    width: 350px;
  }
}

@media (min-width:426px) and (max-width:768px) {
  .design {
    height: 750px;
    width: 500px;
  }
  .design img{
    height: 600px;
    width: 400px;
  }
}
@media (min-width:769px) and (max-width:1024px) {
  .design {
    height: 800px;
    width: 500px;
  }
  .design img{
    height: 700px;
    width: 450px;
 }
}