.tpost {
  background-color: aliceblue;
  box-shadow: 10px 10px 20px 0px rgba(194, 191, 191, 0.75);
  margin-bottom: 20px;
  /* display: flex; */
}
.heading5 {
  margin: 30px 0px;
}
.offerBlock {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.offerBlock img {
  height: 100%;
  width: auto;
}

.box1 {
  /* display: flex; */
  margin-top: 20px;
  box-shadow: 0 10px 4px rgb(0 0 0 / 8%);
  /* border: 2px solid black; */
  /* height: 100px; */
  display: flex;
}
.img1 {
  /* margin-top: 1px; */

  width: 35%;
  height: 100%;
}
.text1 {
  width: 65%;
  margin: 0 0.7vw;
}

.img1 img {
  /* width: 30vw; */
  /* height: 5vw; */
  border-radius: 5px;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  /* border: 2px solid black; */
}
.link {
  display: flex;
}
.text1 h3 {
  font-size: 15px;
  text-align: left;
  margin-top: 0;
  /* margin-left: 6px; */
  word-wrap: normal;
  text-transform: capitalize;
  font-weight: 500;
  font-family: 'poppins';
}
@media screen and (max-width: 1100px) {
  .img1 img {
    width: 50vw;
    height: 8vw;
    border-radius: 8px;
  }
  .text1 h3 {
    font-size: 13px;
    margin-left: 6px;
  }
}
@media screen and (max-width: 998px) {
  .gridmax {
    margin-top: -5vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .img1 img {
    width: 50vw;
    height: 10vw;
    border-radius: 8px;
  }
}
@media screen and (max-width: 698px) {
  .gridmax {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .img1 {
    width: 30%;
  }
  .img1 img {
    width: 70svw;
    height: 15vw;
    border-radius: 8px;
    object-fit: cover;
  }
  .text1 {
    width: 65%;
    margin-top: 0.5vw;
  }
  .box1 {
    margin-bottom: 5px;
  }
  .text1 h1 {
    margin-top: 2px;
    font-size: 13px;
    margin-left: 6px;
  }
}
