.tainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* padding: 0 16px; */
}

.ButtonandTitle{
  display: flex;
  gap: 5px;
}

.backButton{
  display: flex;
  align-items: center;
  color: #c43942;
}

.backButton:hover{
  cursor: pointer;
}

.back{
  color: white;
}

.Headingg {
  display: flex;
  justify-content: start;
}

/* Pagination Styles */
.paginationContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.paginationButton {
  margin: 5px;
  padding: 5px 15px;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.paginationButton:hover {
  background-color: darkgray;
}

.activePage {
  background-color: blue;
  font-weight: bold;
}

/* Latest Page Container */
.latestPageContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 130px;
}

.blogsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  margin-top: 20px;
  margin: auto;
}

/* Block Styles */
.block {
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  box-shadow: 0 10px 4px rgb(0 0 0 / 8%);
  border-radius: 5px;
}

.block:hover {
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  cursor: pointer;
  transform: translateY(-5px);
}

.image {
  width: 100%;
}

.content {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.container {
  display: flex;
}

/* Responsive Styles for smaller screens */
@media screen and (max-width: 1024px) {
  .blogsContainer {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
  }

  .latestPageContainer {
    margin: 0 50px; /* Reduce margin for tablets */
  }

  .paginationButton {
    padding: 5px 12px;
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .blogsContainer {
    grid-template-columns: repeat(1, 1fr); /* Single column for smaller screens */
    gap: 20px; /* Adjust gap between items */
  }

  .latestPageContainer {
    margin: 0 20px; /* Reduce margin on mobile */
  }

  .paginationButton {
    padding: 4px 10px;
    font-size: 12px;
  }

  .block {
    margin-bottom: 15px; /* Add margin between blocks on mobile */
  }

  .image {
    height: 200px; /* Control image size for mobile */
  }
}

@media screen and (max-width: 576px) {
  .blogsContainer {
    grid-template-columns: 1fr; /* 1 column for very small screens */
  }

  .paginationButton {
    padding: 4px 8px;
    font-size: 12px;
  }

  .block {
    margin-bottom: 20px; /* Increased margin on mobile */
  }

  .content {
    padding: 10px;
  }
}

/* Existing styles */
.paginationButton {
  padding: 5px 12px;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
.blogsContainer {
  grid-template-columns: repeat(1, 1fr); /* Single column for smaller screens */
  gap: 20px; /* Adjust gap between items */
}


.tainer{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.latestPageContainer {
  margin: 0 20px; /* Reduce margin on mobile */
}

.paginationButton {
  padding: 4px 10px;
  font-size: 12px;
}

/* New styles for heading and see more button */
.ButtonandTitle {
  align-items: center;
  text-align: left; /* Align text to the left */
  margin: 0; /* Remove any centering margins */
}

.ButtonandTitle h6{
  font-size: 16px;
}

svg{
  width: 27px;
}
}
