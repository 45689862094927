
.head {
  /* position: relative; */
  background-color: #c43942;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-height: 40px; */
}
.containerHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 82vw;
  /* height: 100%; */
  /* width: 1280px; */
  padding: 5px 20px;
}

.headleft {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.headright{
  display: flex;
  align-items: center;
}
.imageWrapper{
  display: flex;
  height: 20px;
  width: 20px;
}
.image {
  height: 90%;
  width: auto;
  object-fit: cover;
}
.num1 {
  color: white;
  font-size: 0.6vw;
}
.num {
 
  color: white;
  font-size: 0.8vw;
}
.paragraph {
  color: white;
  font-size: 0.9vw;
  font-family: 'Poppin';
}
 span{
  margin-left: 0.3vw;
}
.paragraph1 {
  color: white;
  line-height: 22px;
  font-size: 0.8vw;
  font-family: Poppin;
}
/* .navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

 
}

.logo {
  width: 8vw;
  cursor: pointer;
 
}
.left{
  display: flex;
}
.menu {
  display: flex;
  gap: 30px;
  align-items: center;
  font-size: 40px;
  font-weight: 200;
 
}

.menu a {
  color: #f52626;
  font-family: Poppin;
  font-weight: 500;
  text-decoration: none;
 font-size: 1.5vw;
}
.auth-buttons{
  display: flex;
  align-items: center;
}
.auth-buttons button {

  
  background-color:#f52626;
  color: white;
  padding: 0.4vw  2vw;
  border: none;
  border-radius: 4vw;
  cursor: pointer;
}
.auth-buttons button {
  margin-left: 2vw;
  font-family: Poppin;
  font-size: 1.2vw;
}
.auth-buttons button:hover {
  color: rgb(11, 11, 11);
}
.auth-buttons a{
 color: black;
 display: none;
}

.mobile-menu-icon{
  display: none;
} */


/* 
@media screen and (min-width: 1024px){
  .auth-buttons button {

  
    background-color:#f52626;
    color: white;
    padding: 0.4vw  2vw;
    border: none;
    border-radius: 4vw;
    cursor: pointer;
  }
  .auth-buttons button {
    margin-left: 2vw;
    font-family: Poppin;
    font-size: 1vw;
  }
} */
/* @media screen and (max-width: 1024px){
  .menu{
    gap: 2vw;
  }
  .menu a{
  font-size: 10px;

}
}
@media screen and (min-width: 1024px) {
  .menu{
    gap: 2vw;
  }
  .menu a{
  font-size: 14px;

}}
@media screen and (min-width: 1500px) {
  .menu{
    gap: 2vw;
  }
  .menu a{
    font-size: 1.1vw;
   
  
  }
  
} */
@media screen and (max-width: 786px) {
  .menu{
    gap: 2vw;
  }
  .head {
  
    width: 100%;
  }

  .num {
    font-size: 8px;
  }
  .paragraph {
    font-size: 8px;
  }
  .paragraph1 {
    font-size: 8px;
  }
  .logo{
    width: 10vw;
  }
  .menu a{
    font-size: 1.3vw;
  }
}
@media screen and (max-width: 700px) {
  .containerHead{
    width:100%;
  }
  .head {
    display: none;
  }
  .navbar{
    background-color: white;
  }
  .logo img{
    width:200px;
    
    filter:contrast(10);
  }
  .menu{
    display: none;
  }
  .auth-buttons button{
    display: none;
  }
  .auth-buttons a{
    display: none;
  }
  .menu-icon{
    display: block;
    border: none;
    background-color: white;
  }
  .nav-links-mobile{
    position: relative;
    display: block;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
    display: block;
 left: 0;
   margin-top: 10vw;
    transition: all 0.5s ease-out;
 width: 100%;
 
    background-color: rgba(245, 222, 179, 0.629);
    list-style: none;
    border-radius: 20px;
    margin-right: 30px;
   
    box-shadow: rgba(57, 57, 181, 0.23) ;
  

  }
  .nav-links-mobile a{
padding: 32px;
width: 100%;

cursor: pointer;
transition: all 0.5s ease-in-out;
text-align: center;
  }
  .nav-links-mobile a:hover{
    background-color: rgb(238, 9, 9);
    color: white;
    border:1px solid black;
    border-radius: 10px;
  }
  .mobile-menu-icon{
    position: absolute;
    display: block;
    border: none;
    background-color: white;
    right: 20px;
    font-size: 15px;
    margin-top: 5px;
    cursor: pointer;

  }
  /* .image {
    height: 3vw;
    width: 5vw;
  }
  .num {
    font-size: 8px;
  }
  .paragraph {
    display: none;
  } */

  
}
