.footerMain {
  width: 100%;
  background-color: #F1821A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:  0;
}

.containerhead {
  padding-top: 20px;
  /* margin-right: 135px;  
  margin-left: 135px; */
  /* the width was 79vw with no margins */
  width: 82%;
  align-items: center;
  /* border-radius: 3vw; */
}

.footer {
  width: 100%;
  position: relative;
  bottom: 0;
}

.containersection{
 display: flex;
 flex-direction: column;
}

.row1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;
  /* margin-top: 1vw; */
}
.row1 p{
  font-size: 18px;
}
.colum {
  display: flex;
  flex-direction: column;
  gap: 5px; 
}


.colum h3 {
  font-size: 18px;
  font-weight: 600;
  font-family: 'poppins';
  color: white;
}

.colum p {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

/* .colum1 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 1.5vw;
} */
.textG p{
  color: white;
  font-size: 14px;
  font-family: Poppin;
}
.link {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-size: 14px;
  margin: 3px;
  font-family: Poppin;
}

.colum p, .colum3 p {
  margin: 3px;
  font-size: 12px;
  color: white;
  cursor: pointer;
}

.colum h3, .colum3 h3 {
  font-size: 18px;
  color: white;
  font-family: Poppin;
  /* font-weight: 600; */
}

.marqueeContainer {
  overflow: hidden;
  white-space: nowrap;
}

.marqueeContent {
  animation: marquee 5s linear infinite;
}

.c3 {
  display: flex;
  flex-direction: column;
  /* justify-items: center; */
  /* margin: 0; */
}

.colum3{
  padding-top: 0;
  display: flex;
  align-items: start;
  justify-content: start;
}

.colum3 img {
  width: 120px;
  height: auto;
}

.c4 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.icons {
  display: flex;
  gap: 15px;
  align-items: center;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.icons i:hover {
  color: var(--color-primary-variant);
}

.copyright {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* padding: 0.5vw; */
  font-family: Poppin;
  color: white;
  background-color: #C43942;
  width: 100%;
  text-align: center;
  /* margin: 0 0 0 0; */
}

/* .copyright p {
  font-size: clamp(0.8rem, 12px, 1.2rem);
} */

.paragraphh p {
  font-size: 12px;
  color: white;
  margin: 0;
  /* width: 100%; */
}

.connect {
  font-size: 16px;
  color: white;
}

@media screen and (min-width: 1025px) {
  h3 {
    font-size: 18px;
  }

  .colum p {
    font-size: 12px;
  }

  .imageSize {
    width: 140px;
    height: auto;
  }
}

@media screen and (min-width: 801px) and (max-width: 1024px) {
  
  .colum, .colum3 {
    width: max-content;
  }

  .image img {
    width: 120px;
    height: auto;
  }

  .icons {
    font-size: clamp(0.8rem, 20px, 1.2rem);
  }
}

@media screen and (min-width: 300px) and (max-width: 800px) {

  .colum, .colum3 {
    width: 230px;
  }

  .colum {
    /* display: flex; */
    flex-direction: column;
  }

  .colum h3, .colum3 h3 {
    font-size: 18px ;
  }
  
  .image img {
    width: 100px;
    height: auto;
  }

  .colum p, .colum3 p {
    font-size: 12px;
  }

  .link {
    font-size: 12px;
  }

  .c4 h3 {
    font-size: 18px;
  }

  .copyright {  
    /* flex-direction: column; */
    gap: 10px;
  }

  .copyright p {
    font-size: 12px;
  }
  .icons{
    font-size: 15px;
  }

}