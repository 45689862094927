@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    width: 100%;
 
    font-family: 'Poppins', sans-serif;
}

.mainContainer {
    background: linear-gradient(90deg, #a62c2c, #f85757, #ff914d);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ensure it fits the screen */
}

.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.logo {
    margin-bottom: 2rem;
    animation: zoom 2s infinite;
    max-width: 90%;
    display: flex;
    justify-content: center;
}

.icon {
    width: auto;
    height: auto;
    max-width: 60%; /* Prevents overflow */
}

.texts, .contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%;
    max-width: 800px; /* Prevents extra-wide content */
}

h1 {
    font-size: clamp(2rem, 5vw, 4.5rem); /* Responsive font size */
    color: black;
    font-weight: bold;
}

.texts h2 {
    padding-bottom: 1rem;
    text-transform: capitalize;
    color: white;
    font-size: clamp(1.2rem, 3vw, 2.5rem);
}

.contactParagraph {
    color: white;
    margin: 10px 0px;
    font-size: clamp(0.9rem, 2vw, 1.2rem);
}

/* Zoom animation */
@keyframes zoom {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

/* Responsive adjustments */
@media screen and (max-width: 700px) {
    .texts h2 {
        font-size: 2rem;
    }
    .texts h2, .contact p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1024px) {
    .texts h2 {
        font-size: 2rem;
    }
    .texts h2, .contact p {
        font-size: 1.2rem;
    }
}
