.category {
 
  position: relative;
  height: 20vw;
  

}
.category .box {
  border-radius: 0;
  text-align: center;

}

.category p {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
}
.category img {
  width: 100%;
  height: 27vw;
 
  background-position:  center;
  background-size: cover;
}
.category .box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 27vw;
  /* background: rgba(0, 0, 0, 0.5); */
}
.category .box {
  cursor: pointer;
  position: relative;
}
.category .overlay {
  position: absolute;
  top: 80px;
  padding: 20px;
  text-align: center;
  width: 100%;
  z-index: 2;
  color: #fff;
}
.category .overlay h4 {
  font-weight: 500;
  color: rgb(253, 246, 126);
}
.category .overlay p {
  color: #fff;
  font-weight: 300;
  padding: 15px;
}
/*---------------control-btn-------------*/
.controlBtn button {
  /* background: gray; */
  /* opacity: 0.7; */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  padding: 2px;
}

/* .controlBtn button:hover {
  opacity: 0.5;
  color: #0c0b0b33;
} */

.next {
  position: absolute;
  top: 40%;
  right: 20px;
}

.prev {
  position: absolute;
  top: 40%;
  left: 20px;
  z-index: 1;
}
 .icon {
  font-size: 25px;
}
/*---------------control-btn-------------*/
@media screen and (max-width: 768px){

  .category{
    height: 150px;
  }
  .category img {
   
    height: 200px;
 
  }
  .category .box::after {
 
    height: 200px;
 
  }
  .controlBtn button {

    height: 20px;
    width: 20px;
  }
.icon{
  font-size: 15px;
}
}