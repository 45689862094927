/* Main container styling for the Single component */
.Single {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  align-items: center;
}

/* Enhanced image container styling */
.single-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  overflow: hidden; /* Ensures the image doesn't overflow the container */
  border-radius: 8px;
  position: relative; /* For any potential overlay or effects */
}

.single-image-container img {
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}

.single-image-container img:hover {
  transform: scale(1.05); /* Slightly zoom in on hover */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

/* Title styling */
.Single h2 {
  font-size: 25px;
  color: #333;
  margin-top: 20px;
  font-weight: bold;
  display: flex;
  justify-content: start;
}

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Table Styling inside description */
.Single table {
  margin-top: 20px;
}

.Single table th,
.Single table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.Single table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.Single table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.Single table tr:hover {
  background-color: #f1f1f1;
}

.Single table td {
  font-size: 16px;
  color: #555;
}

.Single table th {
  font-size: 18px;
  color: #333;
}

/* Optional: for content inside description if it's too long */
.Single .description {
  line-height: 1.6;
  color: #444;
  display: flex;
  justify-content: center;
  align-items: center;
}