.container {
  margin: 0 9%;
}

* p {
  margin: 0px;
}

.topContent {
  display: flex;
  justify-content: center;
}

.left {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 16px;
  padding: 46px;
  border: #979494 solid 1px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 28px;
  width: fit-content;
  height: 570px;
}

.right {
  margin-top: 16px;
  margin-bottom: 60px;
  border-radius: 28px;
  width: 639px;
  height: 480px;
  position: relative;
  margin-left: -150px;
  z-index: 1;
}

.onlyText {
  background-color: #c43942;
  padding: 30px;
  width: 570px;
  height: 350px;
  border-radius: 20px;
  opacity: 0.8;
  display: flex;
  text-align: justify;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.onlyText .welcome {
  font-size: smaller;
  margin: 0px;
  color: white;
}

.onlyText h2 {
  font-size: 32px;
  text-align: left;
  color: #c43942;
}

.onlyText p {
  font-size: 14px;
  margin: 0px;
  color: white;
}

.mainText p {
  font-size: 14px;
}

.overview {
  margin: 2rem 0;
}

.overview h1 {
  display: flex;
  font-size: 28px;
  justify-content: flex-start;
  color: #c43942;
}

.overview p {
  font-size: 14px;
  text-align: justify;
}

@media (max-width: 1024px) {
  .right {
    margin-left: -250px;
    z-index: 1;
  }

  .left {
    z-index: 2;
  }
}

@media (max-width: 1350px) {
  .right {
    margin-left: -200px;
    z-index: 1;
  }

  .left {
    z-index: 2;
  }
}

@media (max-width: 1290px) {
  .right {
    margin-left: -250px;
    z-index: 1;
  }

  .left {
    z-index: 2;
  }
}

@media (max-width: 1190px) {
  .right {
    margin-left: -350px;
    z-index: 1;
  }

  .left {
    z-index: 2;
  }
}

@media (max-width: 1068px) {
  .right {
    margin-left: -400px;
    z-index: 1;
  }

  .left {
    z-index: 2;
  }
}

@media (max-width: 1024px) {
  .right {
    margin-left: -450px;
    z-index: 1;
  }

  .left {
    z-index: 2;
  }
}

@media (max-width: 958px) {
  .topContent {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 2rem 0;
  }

  .right {
    margin-top: 16px;
    margin-bottom: 10px;
    border-radius: 28px;
    width: 100%;
    max-width: 639px;
    height: auto;
    position: relative;
    z-index: 1;
    margin-left: 0;
  }

  .left {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    padding: 20px;
    border: #979494 solid 1px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 28px;
    width: 100%;
    max-width: 570px;
    height: auto;
  }

  .onlyText {
    background-color: #c43942;
    padding: 30px;
    width: 100%;
    max-width: 570px;
    height: auto;
    border-radius: 20px;
    opacity: 0.8;
    display: flex;
    text-align: justify;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
  }
}

.image {
  display: flex;
  gap: 12px;
}

.image img {
  width: 100%;
  height: 160px;
  margin: 2rem;
}

.imageMV {
  width: 100%;
  display: flex;
  height: 160px;
  margin: 2rem;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text h3 {
  color: #c43942;
}

.mission1 {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}

.mission1 h1 {
  display: flex;
  justify-content: flex-start;
  color: #c43942;
}

.mission p {
  font-size: 14px;
  text-align: justify;
}

@media (max-width: 968px) {
  .image {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .imageMV {
    height: auto;
    justify-content: center;
    align-items: center;
  }

  .imageMV img {
    width: 100%;
    height: auto;
    max-height: 160px;
    object-fit: cover;
  }

  .text {
    text-align: center;
    align-items: center;
    padding: 0 1rem;
  }

  .text h3 {
    text-align: center;
    font-size: 22px;
  }

  .mission1 {
    text-align: center;
  }

  .mission1 h1 {
    justify-content: center;
    font-size: 26px;
  }

  .mission p {
    font-size: 13px;
    padding: 0 1rem;
  }
}

@media (max-width: 412px) {
  .image {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .mission1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .mission1 h1 {
    font-size: 24px;
  }

  .text {
    text-align: center;
    align-items: center;
    padding: 0 1rem;
  }

  .text h3 {
    font-size: 20px;
    margin: 10px 0;
  }

  .imageMV {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageMV img {
    width: 100%;
    max-width: 320px;
    height: auto;
    object-fit: cover;
  }

  .mission1 .image {
    flex-direction: column;
  }

  .mission1 .imageMV {
    order: 2;
  }

  .mission1 .text h3 {
    order: -1;
  }

  .mission p {
    font-size: 12px;
    padding: 0 1rem;
  }
}

@media (max-width: 500px) {
  .image {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .mission1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .mission1 h1 {
    font-size: 24px;
  }

  .text {
    text-align: center;
    align-items: center;
    padding: 0 1rem;
  }

  .text h3 {
    font-size: 20px;
    margin: 10px 0;
  }

  .imageMV {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageMV img {
    width: 100%;
    max-width: 320px;
    height: auto;
    object-fit: cover;
  }

  .mission1 .image {
    flex-direction: column;
  }

  .mission1 .imageMV {
    order: 2;
  }

  .mission1 .text h3 {
    order: -1;
  }

  .mission p {
    font-size: 12px;
    padding: 0 1rem;
  }
}

@media (max-width: 768px) {
  .image {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .mission1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .mission1 h1 {
    font-size: 24px;
  }

  .text {
    text-align: center;
    align-items: center;
    padding: 0 1rem;
  }

  .text h3 {
    font-size: 20px;
    margin: 10px 0;
  }

  .imageMV {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageMV img {
    width: 100%;
    max-width: 320px;
    height: auto;
    object-fit: cover;
  }

  .mission1 .image {
    flex-direction: column;
  }

  .mission1 .imageMV {
    order: 2;
  }

  .mission1 .text h3 {
    order: -1;
  }

  .mission p {
    font-size: 12px;
    padding: 0 1rem;
  }

  .imageMV img {
    width: 60%;
    height: auto;
    min-height: 180px;
  }

  .imageMV {
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.teamSection {
  text-align: center;
}

.teamSection h1 {
  text-align: start;
  color: #c43942;
}

.teamMembers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin: 2rem 0;
  justify-items: center;
}

.memberCard {
  text-align: center;
  border: 1px solid #ddd;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 10px;
  transition: all 0.3s;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.memberCard img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
}

.memberCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.memberName {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .teamMembers {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .teamMembers {
    grid-template-columns: 1fr;
  }
}