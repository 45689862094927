/* General Styles */
* {
  box-sizing: border-box;
}

/* Container Styles */
.containerHead {
  display: flex;
  justify-content: center;
}

.container {
  margin: 40px 0 60px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 15px 25px;
  box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(239, 237, 237, 0.979);
}

.subContainer {
  background-color: #ffffff;
  padding: 15px 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(239, 237, 237, 0.979);
  max-width: 1440px;
  margin: 0 8vw;
}

/* Introduction Section */
.introduction {
  display: flex;
  justify-content: center;
}

.intro {
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

/* Register and Customer Support Sections */
.register,
.customerSupport,
.notice {
  margin-bottom: 15px;
}

.register h1,
.customerSupport h1 {
  font-size: 18px;
  color: #343a34;
  font-weight: 600;
}

.register p,
.customerSupport p,
.notice p {
  color: #343a34;
  font-size: 14px;
}

/* Products Section */
.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(241, 241, 229);
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding-bottom: 50px;
}

.products h2 {
  text-align: center;
  padding-top: 25px;
  font-size: clamp(10px, 3vw, 24px);
}

.AllProducts {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.product {
  background: #ffffff;
  color: #21201e;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1.2rem;
  padding: 20px;
  transition: box-shadow 0.3s ease;
}

.product:hover {
  box-shadow: 5px 15px 25px #eeeeee;
}

.product img {
  height: 100px; /* Fixed height for all images */
  width: 100px; /* Fixed width for all images */
  object-fit: contain; /* Ensures the image fits within the dimensions */
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}

.product:hover img {
  transform: scale(1.1);
}

.ProductInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product .ProductTitle,
.product .ProductPrice {
  font-size: 16px;
  color: #343a34;
  margin: 5px 0; /* Added margin for better spacing */
}

.product a:link,
.product a:visited {
  color: #ececec;
  display: inline-block;
  text-decoration: none;
  background-color: #2c3e50;
  padding: 1rem 2rem; /* Adjusted padding for better proportions */
  border-radius: 1rem;
  margin-top: 1rem;
  font-size: 14px;
  transition: all 0.2s ease;
}

.product a:hover {
  transform: scale(1.05); /* Slightly reduced scale for smoother hover */
}

/* Button Styles */
.btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px;
  background-color: red;
  color: white;
  border-radius: 10px;
  margin-top: 15px;
}

.icons {
  font-size: 18px;
}

.cross {
  font-size: 14px;
}

/* Responsive Styles */
@media (max-width: 1678px) {
  .product {
    width: 200px;
    height: 300px;
  }

  .product img {
    height: 90px; /* Adjusted for smaller screens */
    width: 90px; /* Adjusted for smaller screens */
  }

  .AllProducts {
    gap: 15px;
  }
}

/* Utility Classes */
.para {
  color: white;
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
}

.paragraph {
  margin: 0;
}

.plusMoreSecondRow {
  display: flex;
  justify-content: flex-end;
}