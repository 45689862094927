/* Main Container Styles */
.containerHead6 {
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
}

.container6 {
  padding: 0;
  margin-top: 2vw;
  width: 80vw;
}

/* Transactions Section */
.transactions {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* Header Styles */
.transaction-description h1 {
  font-size: 35px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  color: #343a3f;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  margin-bottom: 20px;
}
.transactionDescription strong h2{

  font-size: 18px;
  color: #343a3f;
}

.container6 h2 {
  font-size: 28px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.container6 h3 {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin-top: 20px;
}

/* Table Styles for Transaction Limits */
.transaction-table,
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.transaction-table th,
table th,
.transaction-table td,
table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.transaction-table th,
table th {
  background-color: #f4f4f4;
  font-weight: 400;
}

.transaction-table td,
table td {
  background-color: #f9f9f9;
}

/* Styling for <figure> elements around the tables */
table {
  margin-top: 10px;
  margin-bottom: 10px;
}

figure.table {
  margin: 0;
  padding: 0;
}

/* Paragraph Styles */
.container6 p {
  color: rgb(53, 53, 53);
  line-height: 1.8;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  text-align: justify;
  margin-bottom: 20px;
}

/* Preformatted Text Styles */
pre {
  background-color: #f8f8f8;
  padding: 10px;
  font-size: 16px;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap; /* Ensure the content wraps correctly */
  word-wrap: break-word;
  margin-top: 10px;
  margin-bottom: 20px;
}

/* strong {
  font-weight: bold;
} */

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .transactions h1 {
    font-size: 30px;
  }

  .container6 h2 {
    font-size: 22px;
  }

  .container6 h3 {
    font-size: 20px;
  }

  .container6 p {
    font-size: 16px;
  }

  .transaction-table th,
  table th,
  .transaction-table td,
  table td {
    font-size: 14px;
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .transactions h1 {
    font-size: 25px;
  }

  .container6 h2 {
    font-size: 18px;
  }

  .container6 h3 {
    font-size: 16px;
  }

  .container6 p {
    font-size: 14px;
  }

  .transaction-table th,
  table th,
  .transaction-table td,
  table td {
    font-size: 12px;
    padding: 8px;
  }
}
