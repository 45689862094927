/* General Overlay Styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

/* Model Container Styles */
.modelContainer {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60vw;
  width: 100%;
  display: flex;
  background-color: white;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.75);
}

/* Left Section (Gradient Background) */
.m {
  width: 60%;
  background: linear-gradient(to bottom, #ff622b, #fe8156);
}

.model, .model1 {
  border-radius: 40px;
  object-fit: cover;
}

.model1 {
  margin: 2vw 0 0 4vw;
  width: 80%;
}

/* Right Section (Form Content) */
.modelRight {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.with, .with1 {
  display: flex;
  align-items: center;
}

.with img, .with1 img {
  width: 5vw;
  margin-left: 0.5vw;
}

.with h1, .with1 h1 {
  font-size: 1.5vw;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-left: 0.5vw;
  color: #ff632e;
}

.closeBtn {
  position: fixed;
  top: 8px;
  right: 8px;
  font-size: 1.2vw;
  font-weight: 600;
  color: black;
  cursor: pointer;
}

/* Content and Form Styles */
.content, .content1 {
  display: flex;
  flex-direction: column;
  margin-top: 4vw;
}

.content1 {
  margin-top: 2.5vw;
  margin-left: 2vw;
}

.form-group, .form-group1 {
  display: flex;
  align-items: center;
  padding: 0.6vw 0;
  border-bottom: 1px solid #ff6c39;
}

.form-group img, .form-group1 img {
  width: 0.7vw;
  margin-right: 7px;
}

.form-group input, .form-group1 input {
  border: none;
  outline: none;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: rgb(78, 74, 74);
}

.form-group input[type="text"],
.form-group input[type="password"],
.form-group1 input[type="text"],
.form-group1 input[type="number"],
.form-group1 input[type="email"],
.form-group1 input[type="date"] {
  font-size: 1vw;
}

.form-group input::placeholder,
.form-group1 input::placeholder {
  font-size: 0.8vw;
  color: rgb(149, 142, 142);
}

/* Radio Button and Gender Group Styles */
.gender-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.radio-group {
  display: flex;
  align-items: center;
}

.radio-group label {
  display: flex;
  align-items: center;
  margin-right: 1vw;
  font-size: 0.8vw;
  color: rgb(78, 74, 74);
}

.radio-group input[type="radio"] {
  margin-right: 0.7vw;
}

/* Remember Me and Sign Up Styles */
.remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1vw;
  color: rgba(0, 0, 0, 0.75);
}

.in {
  display: flex;
  align-items: center;
}

.in input[type="checkbox"] {
  margin-right: 5px;
}

.in label {
  font-size: 0.7vw;
  color: #ff632e;
}

.remember p {
  font-size: 0.7vw;
  color: #ff632e;
  cursor: pointer;
}

/* Button Styles */
.l {
  background-color: #fb541c;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 15px;
  padding: 5px 10px;
  margin: 20px 1vw 0;
  cursor: pointer;
}

/* Sign Up Link Styles */
.sign {
  display: flex;
  justify-content: flex-end;
  margin-top: 1vw;
}

.sign p {
  font-size: 0.8vw;
  color: #fb541c;
}

/* Social Connect Styles */
.connect, .connect1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3vw;
}

.connect p, .connect1 p {
  font-size: 1.5vw;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #ff4000;
}

.conn {
  display: flex;
  gap: 1vw;
  margin-top: 0.5vw;
}

.conn img {
  width: 1.4vw;
  border-radius: 3px;
  background-color: #ff4000;
  cursor: pointer;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .modelContainer {
    max-width: 68vw;
  }

  .form-group img, .form-group1 img {
    width: 1vw;
    height: 1.5vw;
  }

  .form-group input::placeholder,
  .form-group1 input::placeholder {
    font-size: 1vw;
  }

  .l {
    font-size: 10px;
    padding: 1px 3px;
  }

  .connect, .connect1 {
    margin-top: 1vw;
  }

  .conn img {
    width: 1.5vw;
  }
}

@media screen and (max-width: 768px) {
  .modelContainer {
    max-width: 80vw;
  }

  .btnContainer, .btnContainer1 {
    width: 25vw;
  }

  .l {
    font-size: 8px;
    padding: 3px 9px;
  }

  .conn img {
    width: 2vw;
  }
}

@media screen and (max-width: 700px) {
  .modelContainer {
    flex-direction: column;
    top: 30%;
    max-width: 100%;
  }

  .m {
    display: none;
  }

  .modelRight {
    width: 100%;
    padding: 0 5vw;
    margin-bottom: 4vw;
  }

  .with img, .with1 img {
    width: 10vw;
  }

  .with h1, .with1 h1 {
    font-size: 4vw;
  }

  .form-group, .form-group1 {
    padding: 10px 0;
  }

  .form-group img, .form-group1 img {
    width: 10px;
    height: 12px;
  }

  .form-group input, .form-group1 input {
    font-size: 12px;
  }

  .form-group input::placeholder,
  .form-group1 input::placeholder {
    font-size: 10px;
  }

  .l {
    font-size: 15px;
    padding: 1px 3px;
  }

  .connect, .connect1 {
    margin-top: 1vw;
  }

  .connect p, .connect1 p {
    font-size: 3vw;
  }

  .conn img {
    width: 15px;
  }
}