@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}

html {
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

/* 
/* navbar style start  */
/* .main-nav */
.mainNav {
  width: 100%;
  position: sticky;
  z-index: 50;
  background-color: white;
  top: 0;
  height: 8rem;
  display: grid;
  grid-template-columns: 9vw 1fr 2.8fr 0.5fr 7.6vw;
}

.logo {
  display: grid;
  /* background-color: #3b5998; */
  grid-column: 2/3;
  align-items: center;
  justify-content: start;
  align-items: center;
  height: 20px;
  width: 150px;
}
.logo img {
  padding: 10px;
  width: 100%;
  height: 100%;
}

.menuLink {
  grid-column: 3/4;
}
.linkk {
  display: flex;
  text-decoration: none !important;
  justify-content: flex-end;
  cursor: pointer;
  align-items: center;

  color: rgb(255, 0, 0) !important;
}

.menuLinkUl {
  height: 8rem;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  justify-content: flex-end;
  align-items: center;
  gap: 2vw;
  color: rgb(255, 0, 0) !important;
}
.menuLinkLi {
  text-decoration: none;
}
/* .social-media  */
.socialMedia {
  grid-column: 4/5;
}

.socialMediaUl {
  height: 8rem;
  display: grid;
  grid-template-columns: 3fr repeat(3, 1fr);
  align-items: center;
  justify-content: end;
  gap: 2vw;
}
.socialMediaDesktop {
  gap: 2vw;
}
.button {
  border: 2px solid red;

  background-color: white;
  color: red;
  padding: 2px 10px;
  border-radius: 10px;
  cursor: pointer;
}
.button1 {
  border: 2px solid red;
  background-color: red;
  color: white;
  padding: 2px 10px;
  border-radius: 10px;
  cursor: pointer;
}
.button:hover {
  background-color: red;

  color: white;
  border-radius: red;
  transition: background-color 0.5s ease;
}
.socialMediaLi {
  text-align: right;
}

.socialMedia ul li:first-child {
  grid-column: 2/3;
}

/* ----------- Grid part ends ----------------- */

.logo h2 {
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#eee, rgb(69, 57, 248));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo h2 span {
  font-size: 3.5rem;
}

.menuLink ul li {
  font-size: 1.8rem;
}

.menuLink ul li a {
  text-transform: capitalize;
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
}
.menuLink ul li:hover > a {
  transform-origin: left;
  color: rgba(0, 0, 3, 1);
  text-decoration: underline;
  transition: 0.5s;
}

.socialMedia ul li {
  font-size: 1.8rem;
}

.socialMedia .hamburgerMenu {
  display: none;
}

/* responsive css style  */
@media (max-width: 1440px) {
  /* .button{
        border: 2px solid red;
        background-color: red;
        color: white;
        padding: 0.2px 10px;
        border-radius: 10px;
        font-size: 15px;
        
    } */
}
@media (max-width: 1100px) {
  .mainNav {
    height: 7vw;
    grid-template-columns: 10vw 1fr 3fr 1fr 10vw;
  }

  .logo,
  .menuLink ul,
  .socialMedia ul {
    height: 7vw;
  }
  .logo img {
    width: 10vw;
    height: 6vw;
    object-fit: contain;
  }
 
  .linkk{
    font-size: 1.5vw;
  }
  .button {
    font-size: 1.5vw;
  }
  .button1 {
    font-size: 1.5vw;
  }
}
@media (max-width: 798px) {
  .mainNav {
    height: 7vw;
    grid-template-columns: 9.5vw 1fr 3fr 1fr 8vw;
  }

  .logo img {
    width: 15vw;
    height: 8vw;
    margin-top: -5px;
  }
  .linkk {
    font-size: 1.7vw;
  }

  .button {
    font-size: 1.5vw;
  }
  .button1 {
    font-size: 1.5vw;
  }

  .socialMedia {
    height: 50px;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .socialMedia .hamburger-menu {
    display: none;
    font-size: 2.5rem;
  }
}
/*-----hide---*/
@media (max-width: 728px) {
  .mainNav {
    height: 7vw;
    grid-template-columns: 9.5vw 1fr 3fr 1fr 8vw;
  }


  .menuLink,
  .linkk ul li {
    font-size: 15px;
    cursor: pointer;
  }

  .button {
    font-size: 1.5vw;
  }
  .button1 {
    font-size: 1.5vw;
  }

  .socialMedia {
    height: 5rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .socialMedia .hamburger-menu {
    display: none;
    font-size: 2.5rem;
  }
}

/* responsive css style  */
@media (max-width: 700px) {
  .mainNav {
    height: 7rem;
    grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
  }

  .menuLink {
    display: none;
  }
  .logo img {
    width: 125px;
    height: 65px;
  
  }

  .logo,
  .socialMedia ul {
    height: 7rem;
  }

  .mobileMenuLink {
    /* background-color: rgba(248, 240, 240, 0.7); */
    background-color: white;

   
    /* height: 100vh; */
    padding: 10px 0;
    /* border: black 2px solid; */
    top: 0;
    left: 0;
    right: 0;
    z-index: 40;
    display: grid;
    grid-column: 2/5;
    justify-content: start;
    position: relative;
    margin-left: 60vw;
    margin-bottom: 25vw;
    /* border-radius: 5px; */
    /* transition: all 2s linear; */
    transform-origin: top;
    width: 100%;
    box-shadow: rgba(167, 151, 151, 0.3) 0px 50px 100px -20px,
      rgba(242, 84, 84, 0.3) 0px 30px 60px -30px;
  }
  .linkk {
    font-size: 15px;
  }

  .mobileMenuLink ul {
    height: 20rem;
    right: 0;

    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start;
  }
  .mobileMenuLink,
  .linkk ul li {

    width: 100%;
    padding-left: 2rem;
    margin-right: 100px;
  }

  .mobileMenuLink ul li:first-child {
    transition-delay: 0.2s;
    border-bottom: rgb(241, 94, 94) 2px solid;

  }

  .mobileMenuLink ul li{
    border-bottom: rgb(241, 94, 94) 2px solid;

  }

  .socialMedia {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-items: end;
    align-items: center;
    transition: all 2s linear;
    
  }

  .socialMedia .socialMediaDesktop {
    height: 0;
    display: none;
    
  }
  .socialMediaUl {
    height: 0;
    display: none;
    
  }
  .socialMedia {
    height: 7rem;
    display: flex;
    justify-self: end;
    align-items: center;
    
  }

  .socialMedia .hamburgerMenu {
    display: block;
    font-size: 20px;
    color: rgb(179, 2, 2);
  }
}


@media (max-width: 520px) {
  .mainNav {
    height: 6rem;
    grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
  }

  .logo,
  .socialMedia ul {
    height: 6rem;
  }

  .logo h2 {
    font-size: 2rem;
  }

  .socialMedia {
    height: 6rem;
    display: flex;
    justify-self: end;
    align-items: center;
  }

  .socialMedia .hamburgerMenu {
    display: block;
    font-size: 20px;
   
  }



  .heroSection h1 {
    font-size: 3.8rem;
  }
}