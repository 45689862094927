.heading5 {
    margin: 30px 10px;
    /* background-image: url("../../../assets/images/Blog/title_pattern.png"); */
    height: 35px;
    background-position: center;
    justify-content: center;
  }
  .heading5 h6 {
    font-weight: 400;
    background: #c43942;
    color: #fff;
    height: 35px;
    width: fit-content;
    text-align: center;
    align-items: center;
    display: flex;
    padding:  0 0.5vw;
    
   
    font-size: 20px;
  }
  @media screen and (max-width:500px) {
    .heading1 h6{
      font-size: 12px;

    }
    }

    @media screen and (max-width:400px){
      .heading5 h6{
        font-size: 12px;
        padding: 1vw;
      }
    }