.containerHead {
  display: flex;
  justify-content: center;
}
.container {
  /* max-width: 1400px; */
  /* width: 1000px; */
  margin-bottom: 60px;
  margin-top: 40px;
  /* min-height: 50vh; */
  /* display: flex; */
  /* flex-direction: column; */
  background-color: #ffffff;
  padding: 15px 25px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(239, 237, 237, 0.979);
}
.subContainer {
  background-color: #ffffff;
  /* padding: 15px 25px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  /* border: 1px solid rgba(239, 237, 237, 0.979); */
  max-width: 1440px;
  width: 650px;
  min-height:35vh;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.title {
  text-align: center;
  font-size: 28px;
  color: #343a3f;
  font-weight: 500;
  padding-bottom: 5px;
  border-bottom: 1px solid #cfc9c9;
}

.title h2{
  font-size: 20px;
  font-weight: 600;
}
.items{
  display: flex;
  flex-direction: column;
}
.labelling{
  font-weight: bold;
  padding: 15px 0px;
}
.titling{
  border: 1px solid  #cfc9c9;
  padding: 10px;
  border-radius: 5px;
}
.textField{
  border: 1px solid #cfc9c9;
  border-radius: 5px;
  padding: 10px;
}
.validationMessage{
  color: red;
}
.title h1{
  /* border-bottom: 1px; */
}
.introduction {
  display: flex;
  justify-content: center;
}
.intro {
  font-size: 30px;
  font-weight: bold;
  font-family: "Poppins";
}
.register {
  margin-bottom: 15px;
}
.register h1 {
  font-size: 20px;
  color: black;
}
.register p {
  color: black;
  font-size: 16px;
}

.customerSupport h1 {
  font-size: 20px;
  color: black;
}
.customerSupport p {
  color: black;
  font-size: 16px;
}
.notice p {
  color: black;
  font-size: 16px;
}
* {
  box-sizing: border-box;
}
.products {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgb(241, 241, 229);
  color: #ececec;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding-bottom: 50px;
}

.products h2 {
  text-align: center;
  padding-top: 25px;
  font-size: clamp(10px, 3vw, 32px);
}

.AllProducts {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 30px;
}

.product {
  background: #ffffff;
  color: #21201e;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1.2rem;
  padding: 20px 20px;
}
.ProductInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product .ProductTitle,
.product .ProductPrice {
  font-size: 16px;
  color: black;
}

.product:hover img {
  scale: 1.1;
}

.product:hover {
  box-shadow: 5px 15px 25px #eeeeee;
}

.product img {
  height: 90px;
  width: 80px;
  transition: all 0.3s;
  margin-bottom: 15px;
}

.product a:link,
.product a:visited {
  color: #ececec;
  display: inline-block;
  text-decoration: none;
  background-color: #2c3e50;
  padding: 1.2rem 3rem;
  border-radius: 1rem;
  margin-top: 1rem;
  font-size: 14px;
  transition: all 0.2s;
}

.product a:hover {
  transform: scale(1.1);
}

.plusMoreSecondRow {
  display: flex;
  justify-content: flex-end;
}

.btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  background-color: red;
  color: white;
  border-radius: 10px;
  margin-top: 15px;
}
.icons {
  font-size: 18px;
}
.cross {
  font-size: 14px;
}
@media (max-width: 1678px) {
  /* Your CSS styles go here */
  .product {
    width: 200px;
    height: 300px;
  }
  .product img {
    height: 80px;
    width: 75px;
  }
  .AllProducts {
    gap: 15px;
  }
}