.grid3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
.blog{
  /* max-width: 1280px; */
  position: relative;
  
  z-index: 5;
  /* margin-top: 3vw; */

}
  .boxItems {
    transition: 0.5s;
    margin: 5px;
    box-shadow: 0 10px 4px rgb(0 0 0 / 8%);
    padding: 0 5px;
  }
  .boxItems:hover {
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  
    transform: translateY(-5px);
  }
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
    
  }

  .truncateText {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;  /* Limit text to 2 lines */
    overflow: hidden;
    text-overflow: ellipsis; /* Optional, for adding ellipsis (...) at the end */
  }
  .icon {
    margin-right: 10px;
    font-size: 20px;
    margin-bottom: 3px;
  }
  .tag a {
    color: red;
    opacity: 0.7;
    font-family: Poppins;
  }
  .tag {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  h3 {
    font-family: Poppins;
    font-weight: 600;
  }
  p {
    color: #090808;
    font-weight: 400;
    margin: 20px 0;
    font-size: 16px;
    line-height: 25px;
    font-family: Poppins;
  }
  .date {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .date label {
    display: block;
    margin-right: 20px;
  }
  @media screen and (max-width: 1000px) {

    .grid3{ 
      grid-template-columns: repeat(2, 1fr);

    }

  }
  @media screen and (max-width:700px) {

    .grid3{ grid-template-columns: repeat(1, 1fr);

    }}
    @media screen and (max-width:700px){
      .blog{
        margin-top: 50px;
      
    }

  }