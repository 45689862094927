.seeMore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.more button {
  align-items: center;
  border-radius: 5px;
  font-weight: 400;
  background: #c43942;
  color: #fff;
  height: 35px;
  width: fit-content;
  text-align: center;
  display: flex;
  padding: 0.5vw;
  font-size: 14px;
  cursor: pointer;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .seeMore {
    display: flex;
    justify-content: space-between;
  }

  .more button {
    font-size: 18px;
    padding: 0.6vw;
  }
}

@media screen and (max-width: 768px) {
  .seeMore {
    /* flex-direction: column; */
    align-items: center;
  }

  .more button {
    font-size: 16px;
    padding: 0.7vw;
  }
}

@media screen and (max-width: 576px) {
  .seeMore {
    /* flex-direction: column; */
    align-items: center;
    margin-top: 10px;
  }

  .more button {
    font-size: 14px;
    padding: 0.8vw;
  }
}

@media screen and (max-width: 400px) {
  .seeMore {
    /* flex-direction: column; */
    align-items: center;
    margin-top: 8px;
  }

  .more button {
    font-size: 12px;
    padding: 1vw;
  }
}
